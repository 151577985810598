<template>
  <div id="Eastern_Allowance_Change">
    <top></top>
    <div class="Eastern_Allowance_Change_body_div">
      <div class="Eastern_Allowance_Change_body">
        <div class="Eastern_Allowance_Change_input_body">
          <div class="Eastern_Allowance_Change_input_div">
            <span>乘机人姓名：</span>
            <input type="text" v-model="name" placeholder="购买行李额姓名" />
          </div>
          <div class="Eastern_Allowance_Change_input_div">
            <span>乘机人票号：</span>
            <input
              type="text"
              v-model="piao"
              placeholder="格式：购买行李额票号"
            />
          </div>
          <div class="Eastern_Allowance_Change_input_div">
            <span>航段下标：</span>
            <input type="text" v-model="Leg_subscript" placeholder="航段下标" />
          </div>
          <div class="Eastern_Allowance_Change_input_div">
            <span>东航账号：</span>
            <input type="text" v-model="dong_admin" placeholder="登录账号" />
          </div>
          <div class="Eastern_Allowance_Change_input_div">
            <span>东航密码：</span>
            <input type="text" v-model="dong_pwd" placeholder="登录密码" />
          </div>
          <div class="Eastern_Allowance_Change_input_div">
            <span>支付密码：</span>
            <input type="text" v-model="zhifu_pwd" placeholder="支付密码" />
          </div>
          <div class="Eastern_Allowance_Change_input_div">
            <span>手机号：</span>
            <input type="text" v-model="phone" placeholder="接收东航短信号码" />
          </div>
          <div class="Eastern_Allowance_Change_input_div">
            <span>验证码：</span>
            <input type="text" v-model="yanzhengma" placeholder="手机验证码" />
          </div>
          <div class="Eastern_Allowance_Change_input_div">
            <span>行李额度：</span>
            <input type="text" v-model="edu" placeholder="格式:5KG/5PC" />
          </div>
          <div class="Eastern_Allowance_Change_input_div">
            <span>里程：</span>
            <input type="text" v-model="licheng" placeholder="格式:1000" />
          </div>
        </div>
        <table border="1" class="Eastern_Allowance_Change_table">
          <tr>
            <td v-for="(item, index) in shuju" :key="index">{{ item.unit }}</td>
          </tr>
          <tr>
            <td v-for="(item, index) in shuju" :key="index">
              <p>里程：{{ item.mileageAmount }}</p>
            </td>
          </tr>
        </table>
        <div class="Eastern_Allowance_Change_Baggage_Allowance">
          <button @click="sreach_xinglie">查询行李额</button>
          <button @click="get_yanzhengma">获取验证码</button>
          <button @click="baggage_xiadan">行李额下单</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import top from "../components/BodyComponents.vue";
export default {
  name: "Eastern_Allowance_Change",
  data() {
    return {
      name: "",
      piao: "",
      dong_admin: "",
      dong_pwd: "",
      zhifu_pwd: "",
      phone: "",
      yanzhengma: "",
      edu: "",
      licheng: "",
      Leg_subscript: "",

      shuju: [],
      json: "",
      chuandi_name: "",
    };
  },
  components: {
    top,
  },
  mounted() {},
  methods: {
    // 行李额查询
    sreach_xinglie() {
      let arr = [
        this.name,
        this.piao,
        this.dong_admin,
        this.dong_pwd,
        this.Leg_subscript,
      ];
      if (arr.includes("")) {
        this.$message.error("信息不全");
        return false;
      }
      this.$https(
        "luggage/allowance/inside/ticket/validity",
        "passengerLastName=" +
          this.name.substr(0, 1) +
          "&passengerFirstName=" +
          this.name.substr(1, 99) +
          "&ticketNo=" +
          this.piao +
          "&airline=" +
          "MU" +
          "&token=" +
          "23A54261AD8B4BB8242384C48B60E25C" +
          "&account=" +
          this.dong_admin +
          "&accountPwd=" +
          this.dong_pwd +
          "&Leg_subscript=" +
          this.Leg_subscript +
          "&walletPwd=" +
          this.zhifu_pwd,
        "post"
      ).then((res) => {
        if (res.data.rsCode != "000000") return false;
        this.shuju = res.data.data.bageDetail;
        this.json = res.data.data.segmentList;
        this.chuandi_name = res.data.data.passengerFullName;
      });
    },
    // 获取验证码
    get_yanzhengma() {
      if ((this.phone ?? "") == "") {
        this.$message.error("请填写手机号码");
        return false;
      }
      this.$https(
        "luggage/allowance/seed",
        "airline=" + "MU" + "&phone=" + this.phone,
        "post"
      );
    },
    // 行李额下单
    baggage_xiadan() {
      if ((this.json ?? "") == "") {
        this.$message.error("请先查询信息后下单");
        return false;
      }
      if ((this.yanzhengma ?? "") == "") {
        this.$message.error("请填写手机验证码");
        return false;
      }
      this.$https(
        "luggage/allowance/inside/order",
        "airline=" +
          "MU" +
          "&ticketNo=" +
          this.piao +
          "&bageBookingSegList=" +
          JSON.stringify(this.json) +
          "&bageTotalUnit=" +
          this.edu +
          "&passengerFullName=" +
          this.chuandi_name +
          "&contactInfo=" +
          JSON.stringify({ contact: this.name, phone: this.phone }) +
          "&code=" +
          this.yanzhengma +
          "&currency=" +
          "ffp" +
          "&needMileage=" +
          this.licheng +
          "&personnel=" +
          sessionStorage.getItem("nickname") +
          "&account=" +
          this.dong_admin +
          "&accountPwd=" +
          this.dong_pwd +
          "&walletPwd=" +
          this.zhifu_pwd,
        "post"
      );
    },
  },
};
</script>


<style scoped>
</style>