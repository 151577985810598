import { render, staticRenderFns } from "./Eastern_Allowance_Change.vue?vue&type=template&id=4528d9b0&scoped=true&"
import script from "./Eastern_Allowance_Change.vue?vue&type=script&lang=js&"
export * from "./Eastern_Allowance_Change.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4528d9b0",
  null
  
)

export default component.exports